.Activities {
    margin-top: 200px;
}

.Activities>.title>.title_1 {
    font-family: Cormorant-Italic;
    font-size: 4.2em;
    margin: 0 0 0 10%;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
}

.Activities>.title>.title_2 {
    font-family: Fraunces;
    font-size: 6.2em;
    margin: 0 0 0 15%;
    line-height: 0.8em;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
}

.Activities>.title>.title_1.show {
    margin: 0 0 0 15%;
}

.Activities>.title>.title_2.show {
    margin: 0 0 0 20%;
}

.Activities .content {
    display: flex;
    flex-direction: row;
    margin: 50px 10%;
}

.Activities .Activity {
    margin-left: 36px;
}

.Activities .Activity:nth-child(1) {
    margin-left: 0px;
}

@media (max-width: 768px) {
    .Activities {
        margin-top: 130px;
    }

    .Activities>.title>.title_1 {
        margin: 0 0 0 24px;
    }

    .Activities>.title>.title_2 {
        margin: 0 0 0 124px;
    }

    .Activities .content {
        margin: 40px;
    }
}

@media (max-width: 600px) {
    .Activities {
        margin-top: 70px;
    }

    .Activities .content {
        flex-direction: column;
        margin: 24px 24px;
    }

    .Activities>.title>.title_1 {
        font-size: 3.2em;
    }

    .Activities>.title>.title_2 {
        margin: 0 24px;
        font-size: 4.2em;
        text-align: right;
    }

    .Activities .Activity {
        margin-left: 0px;
        margin-top: 24px;
        border-top: 1px solid #AAA;
        padding-top: 24px;
    }

    .Activities .Activity:first-child {
        border-top: none;
        padding-top: 0px;
    }
}