.ParallaxImage {
  position: absolute;
  z-index: 0;
}

@media (max-width: 768px) {
  .desktopOnly {
    display: none;
  }
}

@media (max-width: 600px) {
  .noMobile {
    display: none;
  }
}