.Home {
  max-width: 1920px;
  min-height: 732px;
  margin: auto;
  position: relative;
  background-image: url(../../asset/image/home_header_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 750px;
  background-position: top;
  padding-top: 120px;
  overflow-x: hidden;
}

.Home>.inner {
  background-image: url(../../asset/image/waves.png);
  background-repeat: no-repeat;
  background-size: 100% 900px;
  background-position: bottom;
  overflow: hidden;
}

.Home>.inner>.inside {
  max-width: 1500px;
  margin: auto;
  position: relative;
}

#triangle1 {
  left: 3%;
}

#triangle6 {
  right: 10%;
}

#triangle4 {
  right: "30%";
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .Home {
    padding-top: 110px;
    background-size: 100% 650px;
  }
}

@media (max-width: 600px) {
  .Home {
    background-size: 100% 850px;
    padding-top: 80px;
  }

  #triangle1 {
    left: -100px;
  }

  #triangle4 {
    right: -40px;
  }

  #triangle6 {
    right: -30px;
  }

  .Home>.inner {
    background-size: auto 1200px;
  }
}