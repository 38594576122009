.Where {
  margin-top: 50px;
  color: white;
  padding-bottom: 5px;
}

.Where>.title>.title_1 {
  font-family: Cormorant-Italic;
  font-size: 4.2em;
  margin: 0 0 0 15%;
}

.Where>.title>.title_2 {
  font-family: Fraunces;
  font-size: 6.2em;
  margin: 0 0 0 20%;
  line-height: 0.8em;
}

.Where>.content {
  display: flex;
  flex-direction: row;
  margin: 80px 7%;
}

.Where>.content .contact {
  flex: 1;
  padding-right: 72px;
}

.Where>.content .contact .link {
  margin-bottom: 24px;
}

.Where>.content .contact .link a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  text-decoration: none;
}

.Where>.content .contact .link img {
  margin-right: 12px;
}

.Where>.content .addresses {
  flex: 2;
  padding-left: 72px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.Where>.content .title {
  font-family: Fraunces;
  font-size: 2.5em;
  line-height: 0.8em;
  margin-top: 0;
}

.Where>.content .addresses>.content {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .Where>.content {
    flex-direction: column;
  }

  .Where>.content .contact {
    padding-right: 0px;
    padding-bottom: 36px;
  }

  .Where>.content .contacts-list {
    display: flex;
    flex-direction: row;
  }

  .Where>.content .contact .link {
    margin-bottom: 0px;
  }

  .Where>.content p {}

  .Where>.content .contacts-list p {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 0;
    margin-top: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding-right: 10px;
  }

  .Where>.content .contacts-list p:last-child {
    margin-right: 0px;
    border-right: 0px;
    padding-right: 0px;
  }

  .Where>.content .addresses {
    padding-left: 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 0px;
    padding-top: 36px;
  }
}

@media (max-width: 600px) {
  .Where {}

  .Where>.title>.title_1 {
    font-size: 3.2em;
    margin: 0 0 0 24px;
  }

  .Where>.title>.title_2 {
    font-size: 4.2em;
    margin: 0 24px 0 0;
    text-align: right;
  }

  .Where>.content .contacts-list {
    flex-direction: column;
  }

  .Where>.content .addresses>.content {
    flex-direction: column;
  }

  .Where>.content .contact .link {
    margin-bottom: 24px;
  }

  .Where>.content .contacts-list p {
    border-right: 0;
  }

  .Where>.content .contact {
    padding-bottom: 12px;
  }

  .Where>.content {
    margin: 80px 7% 24px 7%;
  }
}