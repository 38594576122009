.Header {
  display: flex;
  padding-top: 35px;
}

.Header .left {
  flex: 1;
  padding-left: 15%;
  z-index: 1;
}

.Header .left .title {
  font-family: Cormorant-Italic, 'Times New Roman', Times, serif;
  font-size: 4.2em;
  margin: 0px;
}

.Header .left .name {
  width: 100%;
}

.Header .left .name img {
  max-width: 87%;
}

.Header .left .punchline {
  font-family: Cormorant-Italic, 'Times New Roman', Times, serif;
  font-size: 3em;
  margin: 0px;
  color: #EE7601;
  max-lines: 1;
}

.Header .left .description {
  width: 80%;
  font-size: 15px;
  line-height: 25.5px;
}

.Header .right {
  flex: 1;
  display: flex;
  align-items: center;
}

.Header .right img {
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .Header {
    padding-top: 0px;
  }

  .Header .left {
    padding-left: 24px;
    flex: 1.5;
  }

  .Header .left .description {
    width: 95%;
  }

  .Header .right {
    overflow: hidden;
  }

  .Header .right img {
    width: 120%;
    margin-top: 70px;
  }
}

@media (max-width: 600px) {
  .Header {
    flex-direction: column;
    padding: 0 24px;
  }

  .Header .left {
    padding-left: 0;
    flex: 0;
  }

  .Header .left .title {
    font-size: 3.2em;
  }

  .Header .left .punchline {
    font-size: 2.2em;
  }

  .Header .right {
    padding-right: 0;
    flex: 0;
  }

  .Header .right img {
    width: 90%;
    margin-top: 10px;
  }

  .Header .left .name img {
    width: 100%;
    max-width: 100%;
  }
}