.More {
  position: relative;
  display: flex;
}

.More .line_pattern_1 {
  position: absolute;
  z-index: 0;
  left: 11%;
  bottom: 45px;
}

.More .line_pattern_2 {
  position: absolute;
  z-index: 0;
  right: 11%;
  top: 45px;
}

.More .inner {
  margin: 80px 14%;
  display: flex;
  flex-direction: row;
}

.More .inner * {
  z-index: 10;
}

.More .inner .left {
  flex: 1;
}

.More .inner .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.More .inner .right {
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.More .inner .right .title_1 {
  font-family: Cormorant-Italic;
  font-size: 2.1em;
  margin: 0;
}

.More .inner .right .title_2 {
  font-family: Fraunces;
  font-size: 2.2em;
  margin: 0 0 24px 0;
}

.More .inner .right .punchline {
  font-family: Cormorant-Italic;
  font-size: 1.8em;
  margin: 0;
  color: #EE7601;
}

@media (max-width: 768px) {
  .More .inner {
    margin: 64px;
    flex-direction: column;
  }

  .More .inner .left {
    max-height: 400px;
  }

  .More .line_pattern_1 {
    left: 24px;
    bottom: 24px;
  }

  .More .line_pattern_2 {
    right: 24px;
    top: 24px;
  }
}

@media (max-width: 600px) {
  .More .inner {
    margin: 50px 24px;
  }

  .More .line_pattern_1 {
    left: 0px;
  }

  .More .line_pattern_2 {
    right: 0px;
    top: 24px;
  }

  .More .inner .right {

  }
}