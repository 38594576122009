.Activity {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Activity .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
}

.Activity .image img {
    width: 80%;
}

.Activity .title_1 {
    font-family: Cormorant-Italic;
    font-size: 2.1em;
    margin: 0;
    display: flex;
    flex: 0;
}

.Activity .title_1 img {
    margin-right: 8px;
}

.Activity .title_2 {
    font-family: Fraunces;
    font-size: 2.2em;
    margin: 0 0 24px 0;
    display: flex;
    flex: 0;
}

.Activity .description {
    flex: 1;
}

.Activity .action {
    display: flex;
    flex: 0;
    text-decoration: none;
    color: black;
}

.Activity .action img {
    margin-right: 8px;
}

@media (max-width: 600px) {
}