.ActivityDetail {
  margin-top: 150px;
  position: relative;
  padding: 0;
  overflow: hidden;
}

.ActivityDetail>.title>.title_1 {
  font-family: Cormorant-Italic;
  font-size: 4.2em;
  margin: 0 0 0 15%;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.ActivityDetail>.title>.title_2 {
  font-family: Fraunces;
  font-size: 5.2em;
  margin: 0 0 0 20%;
  line-height: 0.8em;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.ActivityDetail .image {
  height: 330px;
  overflow: hidden;
  position: relative;
  margin: 40px 0;
  background-color: white;
}

.ActivityDetail .image .main {
  position: relative;
  width: 100%;
}

.ActivityDetail .image .mask {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.ActivityDetail .content {
  max-width: 600px;
  margin: auto;
  position: relative;
}

.ActivityDetail .content .title {
  display: flex;
  margin-bottom: 40px;
}

.ActivityDetail .content .title .text {
  margin-left: 24px;
  font-size: 38px;
  font-family: Fraunces;
}

.ActivityDetail .content>.text .enhanced {
  font-weight: bold;
}

.ActivityDetail .video {
  position: relative;
  display: flex;
  padding: 100px 0;
}

.ActivityDetail .video .line_pattern_1 {
  position: absolute;
  z-index: 0;
  left: 20%;
  bottom: 65px;
}

.ActivityDetail .video .line_pattern_2 {
  position: absolute;
  z-index: 0;
  right: 20%;
  top: 65px;
}

.ActivityDetail .video .inner {
  width: 90%;
  height: 428px;
  background-color: #DDDDDD;
  margin: auto;
  max-width: 813px;
}

.ActivityDetail .video .inner iframe {
  width: 100%;
  height: 100%;
}

.ActivityDetail .contact {
  background-image: url(../../../asset/image/waves.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  padding: 36px;
}

.ActivityDetail .contact .inner {
  max-width: 768px;
  margin: auto;
}

.ActivityDetail .contact .title {
  font-family: Fraunces;
  font-size: 2em;
  line-height: 0.8em;
  margin-top: 0;
  color: white;
}

.ActivityDetail .contact .link {
  margin-bottom: 24px;
}

.ActivityDetail .contact .link a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  text-decoration: none;
}

.ActivityDetail .contact .link img {
  margin-right: 12px;
}

.ActivityDetail .contact .contacts-list {
  display: flex;
}

.ActivityDetail .contact .contacts-list p {
  flex: 1;
}

.ActivityDetail .NavBar.opaque-background {
  background-color: #FFFFFF;
}

#triangle2 {
  left: -120px;
}

#triangle3 {
  right: 20%;
}

@media (max-width: 768px) {
  .ActivityDetail {
    margin-top: 110px;
  }

  .ActivityDetail .image .main {
    width: 200%;
    left: -50%;
  }

  .ActivityDetail .contact {
    background-size: 200%;
  }
}

@media (max-width: 600px) {
  .ActivityDetail {
    margin-top: 70px;
  }

  .ActivityDetail>.title>.title_1 {
    margin: 0 24px;
  }

  .ActivityDetail>.title>.title_2 {
    margin: 0 24px;
    text-align: right;
  }

  .ActivityDetail .NavBar {
    background-color: #FFFFFF;
  }

  .ActivityDetail .content {
    margin: 0 24px;
  }

  .ActivityDetail .video .inner {
    height: 228px;
  }

  .ActivityDetail .video .line_pattern_1 {
    left: 0;
    bottom: 65px;
    height: 200px;
  }

  .ActivityDetail .video .line_pattern_2 {
    right: 0;
    top: 65px;
    height: 200px;
  }

  .ActivityDetail .contact .contacts-list {
    flex-direction: column;
  }

  .ActivityDetail .image .main {
    width: auto;
    height: 150%;
  }

  #triangle2 {
    left: -200px;
  }

  #triangle3 {
    right: 0;
  }
}