.Address {
  flex: 1;
  margin-right: 36px;
}

.Address:last-child {
  margin-right: 0px;
}

.Address p {
  margin: 0;
}

.Address .header {
  font-family: Cormorant-Italic;
  font-size: 1.7em;
  font-weight: bold;
  margin-bottom: 12px;
}

.Address .line_1 {
  font-weight: bold;
}

.Address .line_1 .light {
  font-weight: normal;
}

.Address .line_3 {
  margin-bottom: 24px;
}

.Address .link {
  background: #EE7601;
  border-radius: 40px;
  padding: 13px 25px;
  flex: 0;
  float: left;

}

.Address .directions_link {
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: white;
}

.Address .directions_link img {
  margin-left: 8px;
}

@media (max-width: 600px) {
  .Address {
    margin-right: 0px;
    margin-bottom: 36px;
  }
}